import { THEME_COLORS } from "styles/Constants";

export const MuiTypography = {
  variants: [
    {
      props: { color: "primary.dark" },
      style: {
        color: THEME_COLORS.primary.dark,
      },
    },
    {
      props: { color: "primary.main" },
      style: {
        color: THEME_COLORS.primary.main,
      },
    },
    {
      props: { color: "secondary.dark" },
      style: {
        color: THEME_COLORS.secondary.dark,
      },
    },
    {
      props: { color: "secondary.main" },
      style: {
        color: THEME_COLORS.secondary.main,
      },
    },
    {
      props: { color: "tertiary" },
      style: {
        color: THEME_COLORS.tertiary.main,
      },
    },
  ],
};
