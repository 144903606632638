import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import th from "date-fns/locale/th";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  styled,
} from "@mui/material";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

const InlineFormControl = styled(FormControl)(({ theme, size, width }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiInputLabel-formControl": {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      position: "static",
      transform: "translate(0,0)",
      flex: `1 0 ${width.label}`,
      paddingTop: theme.spacing(size === "medium" ? 2 : 1),
      paddingLeft: 0,
      [theme.breakpoints.up("md")]: {
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
      },
    },
    "& .MuiInputBase-root": {
      flexBasis: width.input,
    },
  };
});

const ColumnInputWithHelperText = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    "& .MuiFormHelperText-contained": {
      margin: theme.spacing(0.5, 0),
    },
  };
});

const InlineDatePicker = ({
  control,
  defaultValue,
  onChangeInputDate,
  shouldDisableDate,
  ...props
}) => {
  const {
    field: { name, value, onChange },
    fieldState: { error },
  } = useController({ control, defaultValue, name: props.name });

  const onChangeDateTime = (callback) => (newValue) => {
    if (onChangeInputDate) onChangeInputDate(newValue);
    callback(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
      <MobileDatePicker
        okText={props.okText}
        cancelText={props.cancelText}
        toolbarTitle={props.toolbarTitle}
        clearable={props.clearable}
        clearText="ล้างค่า"
        disabled={props.disabled}
        value={value}
        onChange={onChangeDateTime(onChange)}
        shouldDisableDate={shouldDisableDate}
        renderInput={({
          InputProps: {
            endAdornment = (
              <InsertInvitationIcon
                sx={{
                  color: (theme) =>
                    !!error
                      ? theme.palette.error.main
                      : theme.palette.grey[600],
                }}
              />
            ),
          },
          ...renderProps
        }) => {
          const errorText = error?.message ? (
            <FormHelperText error={!!error}>{error.message}</FormHelperText>
          ) : null;
          return (
            <InlineFormControl
              disabled={props.disabled}
              size={props.size}
              width={props.width}
            >
              <InputLabel
                error={!!error}
                htmlFor={`${name}-inline__date-picker`}
              >
                {props.label}
              </InputLabel>
              <ColumnInputWithHelperText>
                <OutlinedInput
                  {...renderProps}
                  id={`${name}-inline__date-picker`}
                  name={name}
                  error={!!error}
                  size={props.size}
                  endAdornment={endAdornment}
                  inputProps={{
                    ...renderProps.inputProps,
                    placeholder: "",
                  }}
                />
                {errorText}
              </ColumnInputWithHelperText>
            </InlineFormControl>
          );
        }}
      />
    </LocalizationProvider>
  );
};

InlineDatePicker.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onChangeInputDate: PropTypes.func,
  shouldDisableDate: PropTypes.func,
  size: PropTypes.oneOf(["medium", "small"]),
  width: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
  }),
  clearable: PropTypes.bool,
};

InlineDatePicker.defaultProps = {
  size: "small",
  width: {
    label: "25%",
    input: "75%",
  },
  okText: "ตกลง",
  cancelText: "ยกเลิก",
  clearable: true,
};

export default InlineDatePicker;
