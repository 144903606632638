// import { lazy, Suspense } from "react";
import { Fragment } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

/* First page for validate user data */
// const UserValidation = lazy(() => import("layouts/UserValidation"));
import UserValidation from "layouts/UserValidation";

/* LAYOUT */
import BlankLayout from "layouts/BlankLayout";
import MainMenuLayout from "layouts/MainMenuLayout";

/* Main page with menu for navigation */
import FarmerMenuPage from "pages/main-menu/FarmerMenuPage";
import DriverMenuPage from "pages/main-menu/DriverMenuPage";

/* Driver Register with GetzTrac */
import RegisterChannelPage from "pages/register/RegisterChannelPage";
import VerifyGetztracTelNumberPage from "pages/register/VerifyGetztracTelNumberPage";
import VerifyGetztracOtpPage from "pages/register/VerifyGetztracOtpPage";
/* Driver Register with new ID */
import NewDriverFormPage from "pages/register/NewDriverFormPage";
import BankAccountFormPage from "pages/register/BankAccountFromPage";

/* Form Page */
// import CommonUserFormPage from "pages/form-sections/CommonUserFormPage";
import AcceptPDPAPage from "pages/form-sections/AcceptPDPAPage";

/* Layout components */
import { Header, Navbar } from "layouts/components";

export const ROUTES = {
  USER_VALIDATION: "/",
  MENU_FARMER: "/menu/farmer",
  MENU_DRIVER: "/menu/driver",
  REGISTER_CHANNEL: "/register",
  VERIFY_GETZTRAC_TEL_NUMBER: "/register/verify-getztrac-tel",
  VERIFY_GETZTRAC_OTP: "/register/verify-getztrac-otp",
  DRIVER_INFORMATION_FORM: "/register/driver/information-form",
  BANK_ACCOUNT_FORM: "/register/driver/bank-account-form",
  VERIFY_OTP: "/register/driver/verify-otp",
  FORM_PDPA_ACCEPTING: "/form/pdpa-accepting",
};

export default function Router() {
  const navigate = useNavigate();

  const registerHeader = {
    left: (
      <Button
        onClick={() => navigate(-1)}
        startIcon={<ArrowBackIosNewIcon sx={{ color: "common.white" }} />}
        sx={{ color: "common.white", p: 0 }}
      >
        ย้อนกลับ
      </Button>
    ),
    right: "ข้อมูลส่วนบุคคล",
  };

  return (
    <Routes>
      <Route path="/" element={<UserValidation />} />
      <Route path="menu" element={<MainMenuLayout />}>
        <Route path="farmer" element={<FarmerMenuPage />} />
        <Route path="driver" element={<DriverMenuPage />} />
      </Route>
      <Route path="register" element={<BlankLayout />}>
        <Route
          index
          element={
            <Fragment>
              <Header textAlign="center">
                <strong>เลือกวิธีการลงทะเบียน</strong>
              </Header>
              <RegisterChannelPage />
            </Fragment>
          }
        />
        <Route
          path="driver/information-form"
          element={
            <Fragment>
              <Navbar content={registerHeader} />
              <NewDriverFormPage />
            </Fragment>
          }
        />
        <Route
          path="driver/bank-account-form"
          element={
            <Fragment>
              <Navbar content={registerHeader} />
              <BankAccountFormPage />
            </Fragment>
          }
        />
        <Route
          path="driver/verify-otp"
          element={
            <Fragment>
              <Header text-textAlign="left">
                <strong>ยืนยันรหัส OTP</strong>
              </Header>
              <VerifyGetztracOtpPage />
            </Fragment>
          }
        />
        <Route
          path="verify-getztrac-tel"
          element={
            <Fragment>
              <Header textAlign="left">
                <strong>ยืนยันหมายเลขโทรศัพท์</strong>
              </Header>
              <VerifyGetztracTelNumberPage />
            </Fragment>
          }
        />
        <Route
          path="verify-getztrac-otp"
          element={
            <Fragment>
              <Header text-textAlign="left">
                <strong>ยืนยันรหัส OTP</strong>
              </Header>
              <VerifyGetztracOtpPage />
            </Fragment>
          }
        />
      </Route>
      <Route path="form" element={<BlankLayout />}>
        {/* <Route index element={REGISTER.index} /> */}
        <Route
          path="pdpa-accepting"
          element={
            <Fragment>
              <Header textAlign="center">
                <strong>เงื่อนไขการเป็นผู้ให้บริการ</strong>
              </Header>
              <AcceptPDPAPage />
            </Fragment>
          }
        />
      </Route>
      <Route path="*" element={<h1>404 Page not found</h1>} />
    </Routes>
  );
}
