import {
  Paper as MuiPaper,
  Box,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { grey } from "@mui/material/colors";

const Paper = styled(MuiPaper)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  padding: theme.spacing(2, 0),
  cursor: "pointer",
  border: `1px solid ${theme.palette.grey[200]}`,
}));

const DashedBox = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "4.5rem",
  width: "4.5rem",
  margin: theme.spacing(0, "auto"),
  border: `2px dashed ${theme.palette.grey[400]}`,
  borderRadius: theme.spacing(0.5),
}));

const AddItemCard = ({ text, clickCard }) => {
  const onClickCardHandler = () => {
    clickCard();
  };

  return (
    <Paper onClick={onClickCardHandler}>
      <Grid container item xs={4}>
        <DashedBox>
          <AddCircleOutlineIcon
            sx={{ m: "auto", fontSize: 40, color: grey[400] }}
          />
        </DashedBox>
      </Grid>
      <Grid container item alignItems="center" xs={8}>
        <Typography variant="body1">{text}</Typography>
      </Grid>
    </Paper>
  );
};

export default AddItemCard;
