import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { format } from "date-fns";

import { Grid, Typography, Fab, styled } from "@mui/material";

import { authActions } from "store/authentication/auth-slice";
import { useHttp } from "hooks";
import { DRIVER_ENDPOINTS } from "constants/api";
import { ROUTES } from "routes/router";
import {
  SelectPicker,
  InlineInput,
  InlineDatePicker,
} from "components/hook-forms";
import { Navbar } from "layouts/components";
import { ActionLoading, Options } from "base-components";

const schemaValidation = yup.object().shape({
  prefixname: yup.string().required("กรุณาเลือก คำนำหน้า"),
  firstname: yup.string().required("กรุณากรอก ชื่อ"),
  surname: yup.string().required("กรุณากรอก นามสกุล"),
  birthDate: yup.date().typeError("กรุณาเลือก วันเดือนปี เกิด"),
  tel: yup
    .string()
    .matches(
      /* eslint-disable-next-line no-useless-escape */
      /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/gm,
      "เบอร์โทรศัพท์ไม่ถูกต้อง"
    )
    .required("กรุณากรอก เบอร์โทรศัพท์"),
});

const Form = styled("form")({
  flexGrow: 1,
});

const NewDriverFormPage = (props) => {
  const dispatch = useDispatch();
  const { prefixname, firstname, surname, tel, birthDate } = useSelector(
    (state) => state.auth
  );
  const [showDrawerPicker, setShowDrawerPicker] = useState(false);
  const navigate = useNavigate();
  const { control, setValue, getValues, handleSubmit, clearErrors } = useForm({
    resolver: yupResolver(schemaValidation),
  });

  const prefixnameValue = getValues("prefixname");

  const { isLoading: isSubmitting, sendRequest: submitRegisterForm } =
    useHttp();

  useEffect(() => {
    if (firstname || surname || tel) {
      setValue("prefixname", prefixname);
      setValue("firstname", firstname);
      setValue("surname", surname);
      setValue("tel", tel);
      setValue("birthDate", birthDate);
    }
  }, [prefixname, firstname, surname, tel, birthDate, setValue]);

  const toggleShowDrawerPickerHandler = () => {
    setShowDrawerPicker((prevShowDrawer) => !prevShowDrawer);
  };

  const submitDriverInformation = (data) => {
    const birthDateString = format(data.birthDate, "dd/MM/yyyy");

    const body = {
      prefixname: data.prefixname,
      firstname: data.firstname,
      surname: data.surname,
      birthDate: birthDateString,
      tel: data.tel,
    };

    submitRegisterForm(
      { endpoint: DRIVER_ENDPOINTS.create, method: "POST", body },
      (createdResponse) => {
        dispatch(
          authActions.updateUser({ ...body, birthDate: data.birthDate })
        );
        navigate(ROUTES.BANK_ACCOUNT_FORM);
      }
    );
  };

  const prefixOptions = [
    {
      id: 1,
      label: <Typography variant="body1">นาย</Typography>,
      value: "นาย",
      onClickOption() {
        setValue("prefixname", "นาย");
        clearErrors("prefixname");
        toggleShowDrawerPickerHandler();
      },
    },
    {
      id: 2,
      label: <Typography variant="body1">นาง</Typography>,
      value: "นาง",
      onClickOption() {
        setValue("prefixname", "นาง");
        clearErrors("prefixname");
        toggleShowDrawerPickerHandler();
      },
    },
    {
      id: 3,
      label: <Typography variant="body1">นางสาว</Typography>,
      value: "นางสาว",
      onClickOption() {
        setValue("prefixname", "นางสาว");
        clearErrors("prefixname");
        toggleShowDrawerPickerHandler();
      },
    },
    {
      id: 4,
      label: <Typography variant="body1">ไม่ระบุ</Typography>,
      value: "ไม่ระบุ",
      onClickOption() {
        setValue("prefixname", "ไม่ระบุ");
        clearErrors("prefixname");
        toggleShowDrawerPickerHandler();
      },
    },
  ];

  return (
    <Fragment>
      <Grid container mt={3} mb={2}>
        <Grid item xs={12} mb={3}>
          <Typography sx={{ color: "error.main", mb: 0.5 }} variant="body2">
            คุณยังไม่เคยกรอกข้อมูลส่วนบุคคล
          </Typography>
          <Typography variant="subtitle2">
            กรุณากรอกข้อมูลส่วนบุคคลก่อนเริ่มใช้งานโหมดคนขับ
          </Typography>
        </Grid>

        <Form onSubmit={handleSubmit(submitDriverInformation)}>
          <Grid item xs={12} mb={2}>
            <Typography variant="body1">
              <strong>1. กรอกข้อมูลส่วนบุคคล</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} mb={2}>
            <SelectPicker
              name="prefixname"
              control={control}
              defaultValue=""
              label="คำนำหน้า"
              width={{ label: "30%", input: "70%" }}
              showDrawer={showDrawerPicker}
              onClickInput={toggleShowDrawerPickerHandler}
            >
              <Navbar
                color="secondary"
                content={{ left: "เลือกคำนำหน้าชื่อ" }}
                position="absolute"
              />
              <Options optionsData={prefixOptions} selected={prefixnameValue} />
            </SelectPicker>
          </Grid>

          <Grid item xs={12} mb={2}>
            <InlineInput
              name="firstname"
              control={control}
              defaultValue=""
              label="ชื่อ"
              width={{ label: "30%", input: "70%" }}
            />
          </Grid>

          <Grid item xs={12} mb={2}>
            <InlineInput
              name="surname"
              control={control}
              defaultValue=""
              label="นามสกุล"
              width={{ label: "30%", input: "70%" }}
            />
          </Grid>

          <Grid item xs={12} mb={2}>
            <InlineDatePicker
              name="birthDate"
              control={control}
              defaultValue=""
              label="วันเดือนปี เกิด"
              toolbarTitle="เลือก วันเดือนปี เกิด"
              width={{ label: "30%", input: "70%" }}
            />
          </Grid>

          <Grid item xs={12} mb={2}>
            <InlineInput
              name="tel"
              control={control}
              defaultValue=""
              label="เบอร์โทรศัพท์"
              type="tel"
              width={{ label: "30%", input: "70%" }}
            />
          </Grid>

          <Grid item xs={12} mb={4}>
            <InlineInput
              name="recommendedCode"
              control={control}
              defaultValue=""
              label="รหัสแนะนำ"
              width={{ label: "30%", input: "70%" }}
            />
          </Grid>

          <Grid container item xs={12}>
            <Fab
              size="small"
              color="secondary"
              variant="extended"
              type="submit"
              sx={{ ml: "auto", px: 3 }}
            >
              ถัดไป
            </Fab>
          </Grid>
        </Form>
      </Grid>

      <ActionLoading open={isSubmitting}>
        <Typography textAlign="center" variant="h5" color="primary.dark">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default NewDriverFormPage;
