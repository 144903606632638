import { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Typography, Fab, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import ReactPinField from "react-pin-field";

import { uiActions } from "store/user-interface/ui-slice";
import { authActions } from "store/authentication/auth-slice";
import { driverActions } from "store/driver/driver-slice";

import { useHttp } from "hooks";
import { ActionLoading, Alert } from "base-components";

import { DRIVER_ENDPOINTS } from "constants/api";
import { ROUTES } from "routes/router";

const useStyles = makeStyles((theme) => ({
  "pin-field": {
    "& swd-pin-field": {
      justifyContent: "space-around",
      "& input": {
        fontFamily: "Prompt",
        backgroundColor: theme.palette.grey[100],
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: theme.shape.borderRadius,
        fontSize: "2rem",
        margin: theme.spacing(2),
        height: "3.5rem",
        outline: "none",
        textAlign: "center",
        transitionDuration: "250ms",
        transitionProperty: "background, color, border, box-shadow, transform",
        width: "20%",
        boxShadow: theme.shadows[2],
      },
      "&[completed]": {
        "& input": {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
  },
}));

const VerifyGetztracOtpPage = (props) => {
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { alert } = useSelector((state) => state.ui);
  const pinRef = useRef(null);

  const navigate = useNavigate();

  const { isLoading: isSending, sendRequest: requestApiOtp } = useHttp();

  const classes = useStyles();

  useEffect(() => {
    const changeInputType = () => {
      pinRef.current.inputs.forEach((input) => {
        // input.inputmode = "numeric";
        input.autocomplete = "one-time-code";
        input.type = "number";
      });
    };

    window.addEventListener("load", changeInputType);

    return () => {
      window.removeEventListener("load", changeInputType);
    };
  }, []);

  const onChangePINHandler = (pin) => {
    setDisabledSubmit(pin.length !== 4);
  };

  const submitOtpHandler = () => {
    let pin = "";
    pinRef.current.inputs.forEach((input) => {
      pin += input.value;
    });
    requestApiOtp(
      {
        endpoint: DRIVER_ENDPOINTS.verifyOtp,
        method: "POST",
        body: {
          tel: auth.tel,
          token: auth.otp.token,
          refno: auth.otp.refno,
          pin,
        },
      },
      (response) => {
        /* OTP is incorrect or expired. */
        if (response.error === "incorrect.") {
          dispatch(
            uiActions.toggleShowAlert({
              isShow: true,
              status: "error",
              duration: 5000,
              message: (
                <Fragment>
                  <Typography component="p" variant="caption">
                    รหัส OTP ไม่ถูกต้อง
                  </Typography>
                  <Typography component="p" variant="caption">
                    กรุณากรอก OTP ใหม่หรือขอ OTP ใหม่อีกครั้ง
                  </Typography>
                </Fragment>
              ),
            })
          );
          pinRef.current.inputs.forEach((input) => (input.value = null));
          return;
        }

        /* Clear OTP verified session */
        dispatch(authActions.clearOtpSession());

        /* Update driver information from Getztrac */
        dispatch(
          authActions.updateUser({
            firstname: response.firstname,
            surname: response.lastname,
            address: response.address,
            district: response.district,
            subDistrict: response.sub_district,
            province: response.province,
            birthDate: response.date_of_birth,
          })
        );

        /* Update services and vehicles from Getztrac */
        if (response?.vehicles?.length > 0)
          dispatch(driverActions.initialDriverInformation(response.vehicles));

        /* Redirect driver to Main menu */
        navigate(ROUTES.MENU_DRIVER);
      }
    );
  };

  const requestOtpAgainHandler = () => {
    requestApiOtp(
      {
        endpoint: DRIVER_ENDPOINTS.verifyTelNo,
        method: "POST",
        body: { tel: auth.tel },
      },
      (response) => {
        dispatch(
          authActions.setOtpSession({
            tel: auth.tel,
            token: response.token,
            refno: response.refno,
            pin: "",
          })
        );
      }
    );
  };

  const onCloseAlert = () => {
    dispatch(uiActions.toggleShowAlert({ isShow: false, status: "error" }));
  };

  const driverTelNo = auth.tel
    ? `${auth.tel.substring(0, 3)}-XXX-${auth.tel.substring(6)}`
    : null;

  const referenceCodeElement = auth.otp.refno ? (
    <Grid container item xs={12} mb={5}>
      <Typography color="secondary" variant="caption" sx={{ mx: "auto" }}>
        รหัสอ้างอิง (Ref): {auth.otp.refno}
      </Typography>
    </Grid>
  ) : null;

  return (
    <Fragment>
      <Box mt={2} sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} mb={0.5}>
            <Typography variant="subtitle2">
              ระบุ OTP ที่ได้รับทาง SMS ที่หมายเลขโทรศัพท์
            </Typography>
          </Grid>
          <Grid item xs={12} mb={0.5}>
            <Typography color="primary" variant="body1">
              <strong>{driverTelNo}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Typography variant="subtitle2" sx={{ color: grey[700] }}>
              OTP มีอายุการใช้งาน 5 นาที
            </Typography>
          </Grid>
          <Grid className={classes["pin-field"]} item xs={12} mb={1}>
            <ReactPinField
              ref={pinRef}
              length={4}
              validate="0123456789"
              onChange={onChangePINHandler}
            />
          </Grid>
        </Grid>
        {referenceCodeElement}
        <Grid container item xs={12} mb={2}>
          <Fab
            variant="extended"
            color="secondary"
            disabled={disabledSubmit}
            onClick={submitOtpHandler}
            sx={{ py: (theme) => theme.spacing(1), mx: "auto", width: "50%" }}
          >
            ตกลง
          </Fab>
        </Grid>
        <Grid onClick={requestOtpAgainHandler} container item xs={12}>
          <Button sx={{ mx: "auto" }}>ขอรหัส OTP อีกครั้ง</Button>
        </Grid>
      </Box>

      <ActionLoading open={isSending} />
      <Alert
        position={{ vertical: "bottom", horizontal: "center" }}
        open={alert.isShow}
        hideDuration={alert.duration}
        onClose={onCloseAlert}
        status={alert.status}
        message={alert.message}
        action={alert.action}
      />
    </Fragment>
  );
};

export default VerifyGetztracOtpPage;
