import { createSlice } from "@reduxjs/toolkit";

const driverInitialState = {
  vehicles: [],
  services: [],
};

const driverSlice = createSlice({
  name: "driver",
  initialState: driverInitialState,
  reducers: {
    initialDriverInformation(state, { payload }) {
      const vehicles = payload.map((vehicle) => ({
        brand: vehicle.brand,
        implements: vehicle.implements,
        nameTH: vehicle.nameTH,
        vinId: 529,
      }));

      const services = [];
      for (const vehicle of payload) {
        services.push(...vehicle.services);
      }

      state.vehicles = vehicles;
      state.services = services;
    },
  },
});

export const driverActions = driverSlice.actions;

export default driverSlice;
