import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "routes/router";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { access_token } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const sendRequest = useCallback(
    async (requestConfig, applyData) => {
      setIsLoading(true);
      const {
        url = process.env.REACT_APP_API_BASE_URL,
        endpoint = "",
        method = "GET",
        headers,
        body,
        signal = null,
      } = requestConfig;
      const apiUrl = `${url}${endpoint}`;
      const headersOption = {
        // token: BACKEND_TOKEN,
        ...((method === "POST" || method === "PUT") && {
          "Content-Type": "application/json",
        }),
        access_token: access_token ? access_token : headers.access_token,
        ...headers,
      };
      if (!headersOption.access_token) return navigate(ROUTES.USER_VALIDATION);
      try {
        const response = await fetch(apiUrl, {
          method,
          headers: headersOption,
          body: body ? JSON.stringify(body) : null,
          signal: signal ? signal : null,
        });
        if (!response.ok) {
          throw new Error("Request failed!");
        }

        const data = await response.json();

        applyData(data);
      } catch (err) {
        console.error(err);
        // setError(err.message || "Something went wrong!");
      }
      setIsLoading(false);
    },
    [access_token, navigate]
  );

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;
