import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { CssBaseline, Container, ButtonGroup, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { components, palette, typography } from "styles/Theme";

import { useHttp } from "hooks";
import { ActionLoading } from "base-components";
import { NavCard } from "layouts/components";

import { ROLES } from "constants/Roles";
import { DRIVER_ENDPOINTS, FARMER_ENDPOINTS } from "constants/api";

let theme = createTheme({
  components,
  palette,
  typography,
});

const MainMenuLayout = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const lastSegment = pathname.split("/").pop();

  const { isLoading, sendRequest } = useHttp();

  const onClickToggleRole = (role) => () => {
    if (!role || role === lastSegment) return;
    const endpoints = {
      farmer: FARMER_ENDPOINTS.checkExists,
      driver: DRIVER_ENDPOINTS.checkExists,
    };
    sendRequest({ endpoint: endpoints[role] }, () => {
      navigate(`/menu/${role}`);
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavCard
        navActions={
          <ButtonGroup>
            <Button
              variant="outlined"
              size="small"
              color={lastSegment === ROLES.FARMER ? "secondary" : "tertiary"}
              onClick={onClickToggleRole(ROLES.FARMER)}
            >
              จ้างงาน
            </Button>
            <Button
              variant="outlined"
              size="small"
              color={lastSegment === ROLES.DRIVER ? "primary" : "tertiary"}
              onClick={onClickToggleRole(ROLES.DRIVER)}
              sx={{
                px: 1.5,
                borderLeftColor: (theme) => {
                  const colors = {
                    farmer: theme.palette.secondary.main,
                    driver: theme.palette.primary.main,
                  };
                  return colors[lastSegment];
                },
              }}
            >
              คนขับ
            </Button>
          </ButtonGroup>
        }
      />
      <Container maxWidth="sm" component="main" sx={{ pt: 1 }}>
        <Outlet />
      </Container>
      <ActionLoading open={isLoading} />
    </ThemeProvider>
  );
};

export default MainMenuLayout;
