import { MuiButton, MuiTypography } from "./components";

import { THEME_COLORS } from "styles/Constants";

export const components = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Prompt';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src:  url('https://krent-font.azureedge.net/fonts/Prompt/truetype/Prompt-Regular.ttf') format('truetype'),
              url('https://krent-font.azureedge.net/fonts/Prompt/woff/Prompt-Regular.woff') format('woff'),
              url('https://krent-font.azureedge.net/fonts/Prompt/woff2/Prompt-Regular.woff2') format('woff2');
      }
    `,
  },
  MuiButton,
  MuiTypography,
};

export const palette = {
  background: {
    default: "#EFEEEE",
  },
  primary: THEME_COLORS.primary,
  secondary: THEME_COLORS.secondary,
  tertiary: THEME_COLORS.tertiary,
};

export const typography = {
  fontFamily: "Prompt",
};
