import PropTypes from "prop-types";

import { Paper as MuiPaper, Grid, Typography, styled } from "@mui/material";

const Paper = styled(MuiPaper)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1.5),
  cursor: "pointer",
  border: `1px solid ${theme.palette.grey[200]}`,
}));

const ImageFarm = styled("img")({
  width: "75%",
  objectFit: "contain",
  margin: "0 auto",
});

const AgriculturalLandCard = ({ imgUrl, landName, landDescription }) => (
  <Paper>
    <Grid container item xs={4}>
      <ImageFarm alt="farm_img" src={imgUrl} />
    </Grid>
    <Grid container item direction="column" xs={8}>
      <Typography variant="body1">
        <strong>{landName}</strong>
      </Typography>
      <Typography variant="subtitle1">{landDescription}</Typography>
    </Grid>
  </Paper>
);

AgriculturalLandCard.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  landName: PropTypes.string,
  landDescription: PropTypes.string,
};

AgriculturalLandCard.defaultProps = {
  landName: "ชื่อ แปลงเกษตร",
  landDescription: "รายละเอียด แปลงเกษตร",
};

export default AgriculturalLandCard;
