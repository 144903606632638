import { Fragment } from "react";
import PropTypes from "prop-types";

import { Grid, Typography, styled } from "@mui/material";

const Option = styled(Grid)(({ theme, selected }) => ({
  flexGrow: 1,
  padding: theme.spacing(1, 0),
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: selected
    ? theme.palette.grey[200]
    : theme.palette.common.white,
  "&:last-child": {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  "&:active": {
    backgroundColor: theme.palette.grey[200],
  },
  "& .MuiTypography-root": {
    color: theme.palette.common.black,
    // margin: theme.spacing(0, "auto"),
  },
}));

const Options = ({ optionsData, selected, alignLabel, sx }) => {
  return (
    <Grid container direction="column" my={5}>
      {optionsData.map((option) => (
        <Fragment key={option.id}>
          <Option
            container
            item
            direction="column"
            alignItems={alignLabel}
            onClick={option.onClickOption}
            selected={selected === option.value}
            sx={sx}
          >
            {option.label}
          </Option>
        </Fragment>
      ))}
    </Grid>
  );
};

Options.propTypes = {
  optionsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node,
      onClickOption: PropTypes.func,
      selected: PropTypes.bool,
    })
  ),
  alignLabel: PropTypes.oneOf(["flex-start", "center", "flex-end"]),
  selected: PropTypes.string,
  sx: PropTypes.object,
};

Options.defaultProps = {
  optionsData: [
    {
      id: 1,
      label: <Typography variant="body1">option 01</Typography>,
      value: "option 1",
      onClickOption: () => {
        console.log("Click option 01");
      },
    },
  ],
  alignLabel: "center",
  sx: {},
};

export default Options;
