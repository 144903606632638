import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_SHOW_DURATION = 3000;
const DEFAULT_STATUS = "info";

const uiInitialState = {
  alert: {
    isShow: false,
    duration: DEFAULT_SHOW_DURATION,
    message: "",
    status: DEFAULT_STATUS,
    action: "",
  },
};

const uiSlice = createSlice({
  name: "user-interface",
  initialState: uiInitialState,
  reducers: {
    toggleShowAlert(state, { payload }) {
      if (!payload.isShow) {
        state.alert.isShow = false;
        state.alert.duration = DEFAULT_SHOW_DURATION;
        state.alert.message = "";
        state.alert.status = payload.status || DEFAULT_STATUS;
        state.alert.action = "";
        return;
      }
      for (const [key, value] of Object.entries(payload))
        state.alert[key] = value;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
