import { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Paper, Typography, Grid, Fab, styled } from "@mui/material";

import { authActions } from "store/authentication/auth-slice";
import { useHttp } from "hooks";
import { ActionLoading, Offset, Footer } from "base-components";
import { FARMER_ENDPOINTS } from "constants/api";
import { ROUTES } from "routes/router";

const ContainedCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 1.5),
  backgroundColor: theme.palette.grey[700],
  borderRadius: theme.spacing(1.5),
  "& .MuiTypography-root": {
    lineHeight: 1.25,
    color: theme.palette.common.white,
  },
}));

const InnerCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1.5),
  "& .MuiTypography-root": {
    lineHeight: 1.5,
    color: theme.palette.common.black,
  },
}));

const AcceptPDPAPage = (props) => {
  const dispatch = useDispatch();
  const [disableCTA, setDisabledCTA] = useState(true);
  const lastParagraphRef = useRef(null);
  const navigate = useNavigate();

  const { isLoading: isUpdating, sendRequest: updateUser } = useHttp();

  useEffect(() => {
    const contentObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDisabledCTA(false);
        } else setDisabledCTA(true);
      },
      { root: null, threshold: 1, rootMargin: "-40px" }
    );

    const toggleConditionAction = () => {
      contentObserver.observe(lastParagraphRef.current);
    };

    window.addEventListener("scroll", toggleConditionAction);

    return () => {
      contentObserver.disconnect();
      window.removeEventListener("scroll", toggleConditionAction);
    };
  }, []);

  const handleAcceptPDPA = () => {
    updateUser(
      {
        endpoint: FARMER_ENDPOINTS.updateUser,
        method: "PUT",
        body: { pdpa_accept: true },
      },
      (response) => {
        dispatch(authActions.updatePDPA(true));
        navigate(ROUTES.FORM_DRIVER_REGISTER);
      }
    );
  };

  return (
    <Fragment>
      <ContainedCard sx={{ mt: 2 }}>
        <Typography component="h6" variant="subtitle1" textAlign="center">
          ข้อกำหนดการเข้าร่วมโครงการ
        </Typography>
        <Typography component="h6" variant="subtitle1" textAlign="center">
          มาตรฐานการให้บริการ
        </Typography>
        <Typography component="h6" variant="subtitle1" textAlign="center">
          ข้อตกลงและเงื่อนไขในการให้บริการ
        </Typography>

        <InnerCard sx={{ mt: 2 }}>
          <Typography variant="subtitle2">
            ข้อกำหนดการเข้าร่วมโครงการ มาตรฐานการให้บริการเงื่อนไขและข้อตกลงนี้
            ("ข้อกำหนด") เป็นเงื่อนไขและข้อตกลงระหว่าง
            อาร์พีจีเดบิตลูปคอมพิวติ้ง แอดมิชชั่นเดสก์ท็อปซ็อกเก็ต
            อีเมลซอร์สเธคพอเพียงยูนิกซ์ คาราโอเกะเวอร์ชวลแพกเกจเน็ตเวิร์คเว็บ
            เปเปอร์กูเกิ้ล ซิมไวแมกซ์โพลล์เทวาธิราชแอปพลิเคชัน
            ป่าไม้โมไบล์เสือโคร่ง ครัวซองอินเตอร์ มาราธอนดิจิตอล โน้ตบุ๊ก ไตรมาส
            หลวงตาแฟลช มัลติทัช พอร์ตเบอร์เกอร์โอเพนออฟไลน์ แหววครัวซองต์
            ไลบรารีอินเทอร์เน็ตแอนด์ เปปเปอร์มินต์แอปพลิเคชั่นเวิร์คสเตชั่น
            ดิจิทัลยูวี ซัมเมอร์ เอาต์พุทคลาสโอยัวะ
            แอนด์แบนด์วิดท์โมเดิร์นเยอร์บีร่า ไดโอด
            จังโก้คอมพ์กราฟิคแม็กกาซีนกูเกิล เมาส์ เอ๋ฟอยล์ แคมฟร็อก
            ซันตาคลอสมอคคา ก๊วนเดสก์ท็อปแอนด์ เมลซันตาคลอสเพียบแปร้ กราฟิกส์
            ไดเอ็ตเวิร์ลด์แคปโมบาย การันตีมาสเตอร์คลิกนู้ดแพลตฟอร์ม
            เย้วออร์เดอร์พาสเวิร์ดแฮปปี้ อูบันตูโดเมนแพกเก็ตเว็บ
            โก๊ะแผดเผาแฟ้บดีไวซ์
          </Typography>
        </InnerCard>

        <InnerCard sx={{ mt: 2 }}>
          <Typography variant="subtitle2">
            สป็อตโปรเจกเตอร์สแควร์ฉลุย โซลูชั่นแอดมิชชั่นคาสิโนแกรนด์ดยุก เกรด
            ฮาร์ดดิสก์คอมไพล์อมาตยาธิปไตย โยเกิร์ตแมค
            ฟลุทสกรีนเซลส์แมนแบคโฮโหงวเฮ้ง พาร์ทิชันวาฟเฟิลไดเรกทอรีอุปสงค์คลิป
            เปียโนไอซีนพมาศไตรมาส แบนด์วิดท์กราฟิกลิงก์ ไซต์โอ้ย วาฟเฟิลเมาส์
            โรลออนบาร์บีคิวคลิกพุทธภูมิอินเตอร์
            ไอพอดไดเอ็ตศิลปวัฒนธรรมปัจฉิมนิเทศอาร์ติสต์ เวิร์ดฟยอร์ดโมดูลเปเปอร์
            เน็ตบุ๊กปัจฉิมนิเทศดิจิตอล โพลล์ ฮากกาโปรเจ็กต์﻿กรรมาชนโฮสต์ไลท์
            ไบนารีไฟต์ ดอส ฮัลโหล ผิดพลาด แบล็กโอ้ยทัวร์นาเมนท์อมาตยาธิปไตยแฟล็ก
            ซีเนอร์เวิร์ม ปักขคณนา เวิร์ก บรอดแบนด์เฟอร์รี่ชิพ
            ทรานแซ็คชั่นออราเคิลยูนิโค้ด พอร์ทโบรชัวร์ปูอัด การันตี
            แอพพลิเคชั่นคอร์รัปชัน เซ็กส์ เซ็กซี่ริคเตอร์มอคค่าฟินิกซ์แล็บ
            สแควร์พอร์ตเวิร์กเทมเพลตเมาส์ ทรานแซ็กชันซูเอี๋ยรีเลย์ซัพพอร์ต
            ลาเต้เจี๊ยว
          </Typography>
        </InnerCard>

        <InnerCard sx={{ mt: 2 }}>
          <Typography variant="subtitle2" ref={lastParagraphRef}>
            คอมมูนิเคชั่นฟลอร์ ออราเคิลซิม เซ็กซี่เชอร์รี่ครัวซองโพรเซสโดเมน
            ฟีเจอร์โปรเจกเตอร์ เยอร์บีร่าสแควร์เอาท์พุตยูนิกซ์ บราวเซอร์
            แบล็กแท็บซีเนียร์เซิร์ฟเวอร์ พลาซ่าสเปกอีสต์เวิร์ดการันตี
            โปรเจกเตอร์แหวว โซลูชั่นโพลารอยด์สปา เบิร์นอุรังคธาตุสแกนเนอร์
            พาร์ทิชันจีพีเอสน้องใหม่แชมปิยองเซ็กซี่
            เนิร์สเซอรีโหลดเดบิตมอนิเตอร์เรียลไทม์
            คอลัมน์คลัสเตอร์ไดรว์เกสต์เฮาส์คอลัมน์ โทรจันทัวร์นาเมนท์
            แฮนด์เฮลด์เทเลคอมครัวซองต์ตาปรือ เกสต์เฮาส์ไงอินเทลจ๊อกกี้
            โครนาตาปรือตังค์เฟิร์มแวร์ โอยัวะแคมฟร็อก
            เทรลเลอร์คอมไพล์สแตนดาร์ดอีเมล พอร์ทเทอร์มินัลแรงผลักโซลูชั่นเทอร์โบ
            ซัมเมอร์รีเฟรชคอร์รัปชันอาร์พีจีเพจเจอร์ โพรเซส แคทวอล์ค ไฟแนนซ์
            แอนะล็อกเทรลเลอร์ เบอร์รีตุ๊กฮิปโปฮับ อพาร์ทเมนต์
            โรลออนโอเพ่นซอฟท์แวร์พอเพียง ขั้นตอนคาราโอเกะ ไฟแนนซ์ฉลุย
            เพนกวินเอ๋อ ดีพาร์ทเมนต์โซลูชั่น คูลเลอร์ สโตนเวอร์ชวล
          </Typography>
        </InnerCard>
      </ContainedCard>

      <Offset sx={{ mt: 1 }} />
      <Footer>
        <Grid container justifyContent="center">
          <Fab
            variant="extended"
            color="secondary"
            size="small"
            disabled={disableCTA}
            onClick={handleAcceptPDPA}
            sx={{ px: (theme) => theme.spacing(2) }}
          >
            ยอมรับ
          </Fab>
        </Grid>
      </Footer>

      <ActionLoading open={isUpdating} />
    </Fragment>
  );
};

export default AcceptPDPAPage;
