import { createSlice } from "@reduxjs/toolkit";

const serviceInitialState = {
  types: [],
};

const serviceSlice = createSlice({
  name: "service",
  initialState: serviceInitialState,
  reducers: {
    updateServiceTypes(state, { payload }) {
      const servicesWithId = payload.map((service, idx) => ({
        id: idx + 1,
        isRegister: false,
        ...service,
      }));
      state.types = servicesWithId;
    },
  },
});

export const serviceActions = serviceSlice.actions;

export default serviceSlice;
