import { createSlice } from "@reduxjs/toolkit";

const masterDataInitialState = {
  banks: [],
};

const masterSlice = createSlice({
  name: "master",
  initialState: masterDataInitialState,
  reducers: {
    createBankNameOptions(state, { payload }) {
      const bankNamesWithId = payload.map((bank, idx) => ({
        id: bank.id,
        ...bank,
      }));
      state.banks = bankNamesWithId;
    },
  },
});

export const masterActions = masterSlice.actions;

export default masterSlice;
