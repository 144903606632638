import { Fragment } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import { TextField, FormHelperText, InputAdornment } from "@mui/material";

const InputTelNumber = ({
  control,
  defaultValue,
  label,
  variant,
  fullWidth,
  autoFocus,
  startAdornment,
  ...props
}) => {
  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const errorText = error?.message ? (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        ) : null;
        return (
          <Fragment>
            <TextField
              name={name}
              label={label}
              type={props.type}
              value={value}
              variant={variant}
              error={!!error}
              onChange={onChange}
              fullWidth={fullWidth}
              autoFocus={autoFocus}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {startAdornment}
                  </InputAdornment>
                ),
              }}
            />
            {errorText}
          </Fragment>
        );
      }}
    />
  );
};

InputTelNumber.propTypes = {
  control: PropTypes.object.isRequired,
  type: PropTypes.string,
  label: PropTypes.node.isRequired,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  startAdornment: PropTypes.node,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
};

InputTelNumber.defaultProps = {
  type: "text",
  variant: "filled",
  startAdornment: "(+66)",
};

export default InputTelNumber;
