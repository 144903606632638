import { createSlice } from "@reduxjs/toolkit";

// import { ROLES } from "constants/Roles";

const authInitialState = {
  /* USER information */
  prefixname: "",
  firstname: "",
  surname: "",
  role: {
    farmer: true,
    driver: false,
  },
  tel: "",
  address: "",
  district: "",
  subDistrict: "",
  province: "",
  birthDate: "",
  bankName: "",
  bankAccount: "",
  recommendedCode: "",
  points: 0,

  /* LINE login */
  access_token: "",
  lineId: "",
  displayName: "",
  pictureUrl: "",
  email: "",
  friendFlag: "",
  pdpa_accept: false,

  /* Register with getztrac session */
  otp: {
    token: "",
    refno: "",
    pin: "",
  },
};

const authSlice = createSlice({
  name: "authentication",
  initialState: authInitialState,
  reducers: {
    updateUser(state, { payload }) {
      for (const [key, value] of Object.entries(payload)) {
        state[key] = value;
      }
    },
    updateRoles(state, { payload: role }) {
      for (const [key, value] of Object.entries(role)) {
        state.role[key] = value;
      }
    },
    updatePDPA(state, { payload }) {
      state.pdpa_accept = payload;
    },
    resetUser(state) {
      state.prefixname = "";
      state.firstname = "";
      state.surname = "";
      state.role.farmer = false;
      state.role.driver = false;
      state.tel = "";
      state.address = "";
      state.district = "";
      state.subDistrict = "";
      state.province = "";
      state.birthDate = "";
      state.recommendedCode = "";
    },

    setOtpSession(state, { payload }) {
      state.tel = payload.tel;
      state.otp.token = payload.token;
      state.otp.refno = payload.refno;
      state.otp.pin = payload.pin;
    },
    clearOtpSession(state) {
      state.otp.token = "";
      state.otp.refno = "";
      state.otp.pin = "";
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
