import { Fragment } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Container,
  Paper,
  Grid,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { ROLES } from "constants/Roles";
import KINLogo from "assets/kaset-inno logo.png";

const AppBar = styled(MuiAppBar)(({ theme, role }) => {
  const themeColor = {
    [ROLES.FARMER]: theme.palette.secondary.main,
    [ROLES.DRIVER]: theme.palette.primary.main,
  };
  return {
    boxShadow: "none",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      height: theme.spacing(14),
      width: "100%",
      backgroundColor: themeColor[role],
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: theme.spacing(14),
      left: 0,
      zIndex: -1,
      height: theme.spacing(3.5),
      width: "100%",
      backgroundColor: themeColor[role],
      borderRadius: "50% / 100%",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  };
});

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(2, 0),
  },
}));

const NavigationCard = styled(Paper)(({ theme }) => ({
  display: "flex",
  width: "100%",
  borderRadius: theme.spacing(1.25),
}));

const ImageLogo = styled("img")({
  width: "9.5rem",
});

const NavCard = ({ navActions }) => {
  const auth = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  const lastSegment = pathname.split("/").pop();

  const pointElement =
    auth.points > 0 ? (
      <Box>
        <Typography component="span" variant="subtitle2" color="error.light">
          คะแนนสะสม:
        </Typography>
        <Typography
          component="span"
          variant="subtitle2"
          color="error.light"
          sx={{ mx: 1 }}
        >
          <strong>{auth.points}</strong>
        </Typography>
        <Typography component="span" variant="subtitle2" color="error.light">
          pts
        </Typography>
      </Box>
    ) : null;

  return (
    <Fragment>
      <AppBar
        color="transparent"
        sx={{ position: "relative", boxShadow: "none" }}
        role={lastSegment}
      >
        <Container maxWidth="sm">
          <Toolbar disableGutters>
            <NavigationCard elevation={2}>
              <Grid container>
                <Grid
                  container
                  item
                  alignItems="center"
                  pl={1}
                  xs={6}
                  sx={{ flexGrow: 1 }}
                >
                  <ImageLogo src={KINLogo} alt="KasetInno__logo" />
                </Grid>
                <Grid item my="auto" ml="auto" pr={2} xs="auto">
                  {navActions}
                </Grid>
                <Grid item xs={12}>
                  <List sx={{ width: "100%", bgColor: "common.white", py: 0 }}>
                    <ListItem alignItems="center" divider sx={{ pt: 0 }}>
                      <ListItemAvatar sx={{ mr: 1 }}>
                        <Avatar
                          alt="line_avatar_img"
                          src={auth.pictureUrl}
                          sx={{ width: 48, height: 48 }}
                        />
                      </ListItemAvatar>
                      <ListItemText>
                        <Grid container>
                          <Grid
                            container
                            item
                            direction="column"
                            xs="auto"
                            sx={{ flexGrow: 1 }}
                          >
                            <Typography
                              component="h2"
                              variant="h6"
                              sx={{ my: auth.points > 0 ? 0 : "auto" }}
                            >
                              {auth.firstname && auth.surname
                                ? `${auth.firstname} ${auth.surname}`
                                : auth.displayName}
                            </Typography>
                            {pointElement}
                          </Grid>
                          <Grid container item xs>
                            <IconButton sx={{ ml: "auto", my: "auto" }}>
                              <MenuIcon fontSize="large" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </NavigationCard>
          </Toolbar>
        </Container>
      </AppBar>
    </Fragment>
  );
};

NavCard.propTypes = {
  navActions: PropTypes.node,
};

export default NavCard;
