import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/es/storage/session";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import uiSlice from "./user-interface/ui-slice";
import authSlice from "./authentication/auth-slice";
import driverSlice from "./driver/driver-slice";
import masterSlice from "./master/master-slice";
import serviceSlice from "./services/service-slice";

const rootPersistConfig = {
  key: "root",
  storage,
  backlist: ["auth", "ui", "services", "master"],
  whitelist: ["driver"],
};

const authPersistConfig = {
  key: "auth",
  storage: sessionStorage,
};

const reducers = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  ui: uiSlice.reducer,
  driver: driverSlice.reducer,
  master: masterSlice.reducer,
  services: serviceSlice.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
