import { Fragment } from "react";

import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  IconButton,
  Drawer as MuiDrawer,
  styled,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const InlineFormControl = styled(FormControl)(({ theme, size, width }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiInputLabel-formControl": {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      position: "static",
      transform: "translate(0,0)",
      flex: `1 0 ${width.label}`,
      paddingTop: theme.spacing(size === "medium" ? 2 : 1),
      paddingLeft: 0,
      [theme.breakpoints.up("md")]: {
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
        // ...(extended && { flex: "1 0 25%" }),
      },
    },
    "& .MuiInputBase-root": {
      flexBasis: width.input,
      // [theme.breakpoints.up("md")]: {
      //   ...(extended && { flexBasis: "75%" }),
      // },
    },
  };
});

const ColumnInputWithHelperText = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    "& .MuiFormHelperText-contained": {
      margin: theme.spacing(0.5, 0),
    },
  };
});

const Drawer = styled(MuiDrawer)({
  position: "relative",
});

const SelectPicker = ({
  control,
  defaultValue,
  multiline,
  rows,
  width,
  showDrawer,
  onClickInput,
  drawerColor,
  drawerHeader,
  drawerContent,
  children,
  ...props
}) => {
  const onClickInputHandler = (e) => {
    e.target.blur();
    onClickInput();
  };

  return (
    <Fragment>
      <Controller
        name={props.name}
        control={control}
        defaultValue={defaultValue}
        render={({
          field: { name, value, onChange },
          fieldState: { error },
        }) => {
          const errorText = error?.message ? (
            <FormHelperText error={!!error}>{error.message}</FormHelperText>
          ) : null;
          return (
            <InlineFormControl
              disabled={props.disabled}
              size={props.size}
              width={width}
            >
              <InputLabel error={!!error} htmlFor={`${name}-inline__input`}>
                {props.label}
              </InputLabel>
              <ColumnInputWithHelperText>
                <OutlinedInput
                  id={`${name}-inline__input`}
                  name={name}
                  type={props.type}
                  multiline={multiline}
                  rows={rows}
                  value={value}
                  onChange={onChange}
                  onClick={onClickInputHandler}
                  error={!!error}
                  size={props.size}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <ArrowDropDownIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errorText}
              </ColumnInputWithHelperText>
            </InlineFormControl>
          );
        }}
      />
      <Drawer anchor="bottom" open={showDrawer} onClose={onClickInput}>
        {children}
      </Drawer>
    </Fragment>
  );
};

SelectPicker.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  size: PropTypes.oneOf(["medium", "small"]),
  width: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
  }),
  showDrawer: PropTypes.bool,
  onClickInput: PropTypes.func,
  children: PropTypes.node,
};

SelectPicker.defaultProps = {
  type: "text",
  disabled: false,
  multiline: false,
  size: "small",
  width: {
    label: "25%",
    input: "75%",
  },
  showDrawer: false,
  onClickInput: () => {},
};

export default SelectPicker;
