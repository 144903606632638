import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Typography, Fab, styled } from "@mui/material";

import { authActions } from "store/authentication/auth-slice";
import { uiActions } from "store/user-interface/ui-slice";

import { useHttp } from "hooks";
import { InputTelNumber } from "components/hook-forms";
import { ActionLoading, Alert } from "base-components";

import { ROUTES } from "routes/router";
import { DRIVER_ENDPOINTS } from "constants/api";

const schema = yup.object().shape({
  tel: yup
    .string()
    .required("กรุณากรอก เบอร์โทรศัพท์")
    .matches(
      /* eslint-disable-next-line no-useless-escape */
      /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/gm,
      "เบอร์โทรศัพท์ไม่ถูกต้อง"
    ),
});

const Form = styled("form")({
  width: "100%",
});

const VerifyGetztracTelNumberPage = (props) => {
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state.ui);

  const navigate = useNavigate();

  const { control, setValue, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const { isLoading: isSending, sendRequest: submitVerifyTelNo } = useHttp();

  const submitTelNumberHandler = (data) => {
    submitVerifyTelNo(
      { endpoint: DRIVER_ENDPOINTS.verifyTelNo, method: "POST", body: data },
      (response) => {
        if (response.reason) {
          dispatch(
            uiActions.toggleShowAlert({
              isShow: true,
              duration: 4000,
              message: `ไม่พบหมายเลขโทรศัพท์ ${data.tel} ในระบบ Getztrac`,
              status: "error",
            })
          );
          setValue("tel", "");
          return;
        }
        dispatch(
          authActions.setOtpSession({
            tel: data.tel,
            token: response.token,
            refno: response.refno,
            pin: "",
          })
        );
        navigate(ROUTES.VERIFY_GETZTRAC_OTP);
      }
    );
  };

  const onCloseAlert = () => {
    dispatch(uiActions.toggleShowAlert({ isShow: false, status: "error" }));
  };

  return (
    <Box mt={2} sx={{ flexGrow: 1 }}>
      <Form onSubmit={handleSubmit(submitTelNumberHandler)}>
        <Grid container>
          <Grid item xs={12} mb={3}>
            <Typography variant="subtitl2">
              ระบุ หมายเลขโทรศัพท์ ที่ใช้ใน Getztrac
            </Typography>
          </Grid>
          <Grid item xs={12} mb={5}>
            <InputTelNumber
              name="tel"
              control={control}
              defaultValue=""
              label="หมายเลขโทรศัพท์"
              type="tel"
              variant="standard"
              fullWidth
              autoFocus
            />
          </Grid>

          <Grid item mx="auto">
            <Fab
              type="submit"
              variant="extended"
              color="secondary"
              sx={{ px: (theme) => theme.spacing(3) }}
            >
              ตกลง
            </Fab>
          </Grid>
        </Grid>
      </Form>

      <Alert
        position={{ vertical: "bottom", horizontal: "center" }}
        open={alert.isShow}
        hideDuration={alert.duration}
        onClose={onCloseAlert}
        status={alert.status}
        message={alert.message}
      />

      <ActionLoading open={isSending} />
    </Box>
  );
};

export default VerifyGetztracTelNumberPage;
