export const FARMER_ENDPOINTS = {
  checkExists: "/farmers/exists",
  farmer: "/farmers/id",
  updateFarmer: "/farmers/update",
};

export const DRIVER_ENDPOINTS = {
  checkExists: "/drivers/exists",
  create: "/drivers/create",
  update: "/drivers/update",
  driver: "/drivers/id",
  serviceTypes: "/services/types",
  bankLists: "/drivers/banklists ",
  verifyTelNo: "/drivers/verify",
  verifyOtp: "/drivers/verifyotp",
  /* Temp */
  resetRegisterGetztrac: "/drivers/reset",
};
