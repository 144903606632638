import { useNavigate } from "react-router-dom";

import { Grid, Typography, Fab } from "@mui/material";
import { grey } from "@mui/material/colors";

import { ROUTES } from "routes/router";

const RegisterChannelPage = (props) => {
  const navigate = useNavigate();

  const registerNewDriverHandler = () => {
    navigate(ROUTES.DRIVER_INFORMATION_FORM);
  };

  const registerWithGetztracHandler = () => {
    navigate(ROUTES.VERIFY_GETZTRAC_TEL_NUMBER);
  };

  return (
    <Grid container sx={{ flexGrow: 1 }}>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        direction="column"
        xs={12}
        sx={{ height: "30vh" }}
      >
        <Typography component="p" variant="subtitle2" sx={{ color: grey[700] }}>
          คุณยังไม่เคยลงทะเบียนรับงาน
        </Typography>
        <Typography component="p" variant="subtitle2" sx={{ color: grey[700] }}>
          กรุณาลงทะเบียนใหม่ก่อนเริ่มใช้งาน
        </Typography>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Fab
          onClick={registerNewDriverHandler}
          variant="extended"
          color="secondary"
          sx={(theme) => ({ px: theme.spacing(5), mb: theme.spacing(3) })}
        >
          ลงทะเบียนใหม่
        </Fab>
        <Fab
          onClick={registerWithGetztracHandler}
          variant="extended"
          color="secondary"
          sx={(theme) => ({ px: theme.spacing(3) })}
        >
          คนขับ Getztrac
        </Fab>
      </Grid>
    </Grid>
  );
};

export default RegisterChannelPage;
