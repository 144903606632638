import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Backdrop, CircularProgress } from "@mui/material";

import { authActions } from "store/authentication/auth-slice";
import { useHttp } from "hooks";

import { FARMER_ENDPOINTS } from "constants/api";

import { ROUTES } from "routes/router";

const { liff } = window;

const UserValidation = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sendRequest: fetchCheckExistsUser } = useHttp();
  const { sendRequest: fetchUserData } = useHttp();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const initailLineLiff = async (signal) => {
      await liff.init({
        liffId: process.env.REACT_APP_LINE_LIFF_ID,
        withLoginOnExternalBrowser: true,
      });

      if (liff.isLoggedIn()) {
        const { displayName, pictureUrl, userId } = await liff.getProfile();
        const { email } = await liff.getDecodedIDToken();
        const { friendFlag } = await liff.getFriendship();
        const accessToken = liff.getAccessToken();

        const lineProfile = {
          displayName,
          pictureUrl,
          lineId: userId,
          email,
          friendFlag,
          access_token: accessToken,
        };

        const checkExistsUser = ({ result }) => {
          if (result)
            fetchUserData(
              {
                endpoint: FARMER_ENDPOINTS.farmer,
                headers: { access_token: accessToken },
                signal,
              },
              (authResp) => {
                const userData = {
                  ...lineProfile,
                  prefixname: authResp.prefixname,
                  firstname: authResp.firstname,
                  surname: authResp.surname,
                  tel: authResp.tel,
                  birthDate: authResp.birthDate,
                  lastLogin: authResp.lastLogin,
                  recommendedCode: authResp.recommendedCode,
                };
                dispatch(authActions.updateUser(userData));
                dispatch(authActions.updateRoles(authResp.role));
                if (authResp.lastLogin === "farmer")
                  return navigate(ROUTES.MENU_FARMER, { replace: true });
                if (authResp.lastLogin === "driver")
                  return navigate(ROUTES.MENU_DRIVER, { replace: true });
              }
            );
          else {
            dispatch(authActions.updateUser(lineProfile));
            dispatch(authActions.resetUser());
            navigate(ROUTES.MENU_FARMER, { replace: true });
          }
        };

        fetchCheckExistsUser(
          {
            endpoint: FARMER_ENDPOINTS.checkExists,
            headers: { access_token: accessToken },
            signal,
          },
          checkExistsUser
        );
      } else liff.login();
    };

    if (liff) initailLineLiff(signal);

    return () => abortCont.abort();
  }, [dispatch, navigate, fetchCheckExistsUser, fetchUserData]);

  return (
    <Backdrop
      open={true}
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 1,
        opacity: 0.5,
        transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      })}
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default UserValidation;
