import PropTypes from "prop-types";

import { styled } from "@mui/material";

const Gap = styled("div")(({ theme, gap }) => {
  if (gap) return { minHeight: theme.spacing(gap) };
  return theme.mixins.toolbar;
});

const Offset = ({ gap }) => <Gap gap={gap} />;

Offset.propTypes = {
  gap: PropTypes.number,
};

export default Offset;
