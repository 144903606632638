import { common } from "@mui/material/colors";

import { THEME_COLORS } from "styles/Constants";

export const MuiButton = {
  variants: [
    {
      props: { variant: "outlined", color: "primary" },
      style: {
        backgroundColor: THEME_COLORS.primary.bright,
      },
    },
    {
      props: { variant: "outlined", color: "secondary" },
      style: {
        backgroundColor: THEME_COLORS.secondary.bright,
      },
    },
    {
      props: { variant: "outlined", color: "tertiary" },
      style: {
        border: `1px solid ${THEME_COLORS.tertiary.main}`,
        color: THEME_COLORS.tertiary.main,
        backgroundColor: common.white,
      },
    },
    {
      props: { variant: "outlined", color: "clear" },
      style: {
        backgroundColor: "transparent",
        border: "1px solid white",
        color: "white",
      },
    },
  ],
};
