import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Grid, Typography, Fab, styled } from "@mui/material";

import { useHttp } from "hooks";
import { authActions } from "store/authentication/auth-slice";
import { masterActions } from "store/master/master-slice";
import { DRIVER_ENDPOINTS } from "constants/api";
import { ROUTES } from "routes/router";

import { SelectPicker, InlineInput } from "components/hook-forms";
import { Navbar } from "layouts/components";
import { Options, ActionLoading } from "base-components";

const Form = styled("form")({
  flexGrow: 1,
});

const BankAccountFormPage = (props) => {
  const dispatch = useDispatch();
  const { tel } = useSelector((state) => state.auth);
  const { banks } = useSelector((state) => state.master);
  const [showDrawerPicker, setShowDrawerPicker] = useState(false);
  const navigate = useNavigate();
  const { control, handleSubmit, getValues, setValue, clearErrors } = useForm();

  const bankNameValue = getValues("bankName");

  const { sendRequest } = useHttp();
  const { isLoading: isSubmitting, sendRequest: submitBankData } = useHttp();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const fetchHttp = (signal) => {
      sendRequest({ endpoint: DRIVER_ENDPOINTS.bankLists, signal }, (banks) => {
        const bankOptions = banks.map(({ bankName }, idx) => ({
          id: idx + 1,
          label: <Typography variant="body1">{bankName}</Typography>,
          value: bankName,
          onClickOption() {
            setValue("bankName", bankName);
            clearErrors("bankName");
            toggleShowDrawerPickerHandler();
          },
        }));
        dispatch(masterActions.createBankNameOptions(bankOptions));
      });
    };

    if (!banks?.length) fetchHttp(signal);

    return () => abortCont.abort();
  }, [banks, sendRequest, dispatch, bankNameValue, setValue, clearErrors]);

  const toggleShowDrawerPickerHandler = () => {
    setShowDrawerPicker((prevShowDrawer) => !prevShowDrawer);
  };

  const submitUpdateBankAccount = (data) => {
    const body = {
      bankName: data.bankName,
      bankAccount: data.bankAccount,
    };
    // if (!data.bankName || !data.bankAccount) return navigate();
    submitBankData(
      {
        endpoint: DRIVER_ENDPOINTS.update,
        method: "PUT",
        ...(data.bankName && data.bankAccount ? body : null),
      },
      (updateResp) => {
        dispatch(authActions.updateUser(body));
        dispatch(authActions.setOtpSession({ ...updateResp, tel }));
        navigate(ROUTES.VERIFY_OTP);
      }
    );
  };

  return (
    <Fragment>
      <Grid container mt={3} mb={2}>
        <Grid item xs={12} mb={3}>
          <Typography sx={{ color: "error.main", mb: 0.5 }} variant="body2">
            คุณยังไม่เคยกรอกข้อมูลส่วนบุคคล
          </Typography>
          <Typography variant="subtitle2">
            กรุณากรอกข้อมูลส่วนบุคคลก่อนเริ่มใช้งานโหมดคนขับ
          </Typography>
        </Grid>

        <Form onSubmit={handleSubmit(submitUpdateBankAccount)}>
          <Grid item xs={12} mb={4}>
            <Typography variant="body1">
              <strong>2. ระบุเลขบัญชีธนาคารเพื่อใช้รับเงิน</strong>
            </Typography>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                textIndent: theme.spacing(2.25),
                color: theme.palette.error.main,
              })}
            >
              สามารถระบุภายหลังก่อนรับงานได้ (ข้ามได้)
            </Typography>
          </Grid>

          <Grid item xs={12} mb={2}>
            <Typography variant="body2" color="secondary.main">
              กรุณากรอกเลขบัญชีธนาคารสำหรับรับเงินผ่านระบบ
            </Typography>
          </Grid>

          <Grid item xs={12} mb={2}>
            <Typography variant="body1">
              เลขบัญชีธนาคาร สำหรับรับเงิน
            </Typography>
          </Grid>

          <Grid item xs={12} mb={2}>
            <SelectPicker
              name="bankName"
              control={control}
              defaultValue=""
              label="ธนาคาร"
              width={{ label: "30%", input: "70%" }}
              showDrawer={showDrawerPicker}
              onClickInput={toggleShowDrawerPickerHandler}
            >
              <Navbar
                color="secondary"
                content={{ left: "เลือกธนาคาร" }}
                position="absolute"
              />
              <Options
                optionsData={banks}
                alignLabel="flex-start"
                selected={bankNameValue}
                sx={{ pl: 2 }}
              />
            </SelectPicker>
          </Grid>

          <Grid item xs={12} mb={4}>
            <InlineInput
              name="bankAccount"
              control={control}
              defaultValue=""
              label="เลขที่บัญชี"
              width={{ label: "30%", input: "70%" }}
            />
          </Grid>

          <Grid container item xs={12}>
            <Fab
              size="small"
              color="secondary"
              variant="extended"
              type="submit"
              sx={{ ml: "auto", px: 3 }}
            >
              ถัดไป
            </Fab>
          </Grid>
        </Form>
      </Grid>

      <ActionLoading open={isSubmitting}>
        <Typography textAlign="center" variant="h5" color="primary.dark">
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default BankAccountFormPage;
