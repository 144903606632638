import { Fragment } from "react";
import PropTypes from "prop-types";

import { AppBar, Toolbar, Container, Grid } from "@mui/material";

import { Offset } from "base-components";

const Navbar = ({ color, content, position }) => {
  const centerElement = content.center ? (
    <Grid item mx="auto" sx={{ flexGrow: 1 }}>
      {content.center}
    </Grid>
  ) : null;

  const rightElement = content.right ? (
    <Grid item ml={!content.center ? "auto" : 0}>
      {content.right}
    </Grid>
  ) : null;

  return (
    <Fragment>
      <AppBar
        position={position}
        sx={color ? { bgcolor: (theme) => theme.palette[color].main } : null}
      >
        <Toolbar>
          <Container maxWidth="sm" sx={{ px: 0 }}>
            <Grid container alignItems="center">
              <Grid item sx={!content.center && { flexGrow: 1 }}>
                {content.left}
              </Grid>
              {centerElement}
              {rightElement}
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
      <Offset />
    </Fragment>
  );
};

Navbar.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary"]),
  position: PropTypes.string,
  content: PropTypes.shape({
    left: PropTypes.node.isRequired,
    center: PropTypes.node,
    right: PropTypes.node,
  }),
};

Navbar.defaultProps = {
  position: "fixed",
};

export default Navbar;
