import { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import { ServiceTypeCard, AddItemCard } from "components";

import { useHttp } from "hooks";
import { serviceActions } from "store/services/service-slice";

import { DRIVER_ENDPOINTS } from "constants/api";

const FarmerMenuPage = (props) => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services);
  const { sendRequest: fetchServicesTypes } = useHttp();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const fetchHttp = (signal) => {
      fetchServicesTypes(
        { endpoint: DRIVER_ENDPOINTS.serviceTypes, signal },
        (response) => {
          dispatch(serviceActions.updateServiceTypes(response));
        }
      );
    };

    if (!services?.types.length) fetchHttp(signal);

    return () => abortCont.abort();
  }, [dispatch, services, fetchServicesTypes]);

  const serviceTypeElements = services?.types?.length
    ? services.types.map((service) => (
        <ServiceTypeCard
          key={service.id}
          imgUrl={service.image}
          name={service.nameTH}
          overlay={false}
        />
      ))
    : null;

  return (
    <Fragment>
      <Grid container>
        <Grid container item xs={12} mb={2}>
          <Typography component="h1" variant="h6">
            <strong>เริ่มต้นจ้างงานทันที</strong>
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          sx={{ flexGrow: 1 }}
        >
          {serviceTypeElements}
        </Grid>
      </Grid>

      <Grid container pt={2}>
        <Grid container item xs={12} mb={2}>
          <Typography component="h1" variant="h6">
            <strong>แปลงเกษตรของฉัน</strong>
          </Typography>
          <Typography variant="subtitle1" sx={{ my: "auto", ml: "auto" }}>
            รายละเอียด
          </Typography>
        </Grid>

        <Grid container item xs={12} mb={2}>
          <AddItemCard text="เพิ่มแปลง" />
        </Grid>
      </Grid>

      <Grid container pt={2}>
        <Grid container item xs={12} mb={2}>
          <Typography component="h1" variant="h6">
            <strong>สถานะงาน</strong>
          </Typography>
          <Typography variant="subtitle1" sx={{ my: "auto", ml: "auto" }}>
            รายละเอียด
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Typography variant="h6" textAlign="center" color="secondary">
            คุณยังไม่มีการจ้างงาน
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default FarmerMenuPage;
