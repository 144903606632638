import PropTypes from "prop-types";

import { Paper as MuiPaper, Grid, Typography, styled } from "@mui/material";
import TractorIcon from "@mui/icons-material/Agriculture";

const Paper = styled(MuiPaper)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1.5),
  cursor: "pointer",
  border: `1px solid ${theme.palette.grey[200]}`,
}));

const ImageVehicle = styled("img")({
  width: "75%",
  objectFit: "contain",
  margin: "0 auto",
});

const VehicleCard = ({ imgUrl, vehicleType, brand }) => {
  const imageElement = imgUrl ? (
    <ImageVehicle alt="farm_img" src={imgUrl} />
  ) : (
    <TractorIcon
      sx={{ m: (theme) => theme.spacing(1, "auto"), fontSize: "3.5rem" }}
    />
  );

  return (
    <Paper>
      <Grid container item xs={4}>
        {imageElement}
      </Grid>
      <Grid container item direction="column" xs={8}>
        <Typography variant="body1">
          <strong>{vehicleType}</strong>
        </Typography>
        <Typography variant="subtitle1">{brand}</Typography>
      </Grid>
    </Paper>
  );
};

VehicleCard.propTypes = {
  imgUrl: PropTypes.string,
  vehicleType: PropTypes.string,
  brand: PropTypes.string,
};

VehicleCard.defaultProps = {
  vehicleType: "เครื่องจักร",
  brand: "Kubota",
};

export default VehicleCard;
