import PropTypes from "prop-types";
import { format } from "date-fns";
import { th } from "date-fns/locale";

import {
  Paper as MuiPaper,
  Grid,
  Typography,
  IconButton,
  styled,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const Paper = styled(MuiPaper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
}));

const WorkbookCard = ({ date, jobLists, clickAdd }) => {
  const monthTh = format(date, "LLLL", { locale: th });
  const dateTh = format(date, "dd", { locale: th });

  const onClickAddHandler = () => {
    clickAdd();
  };

  return (
    <Paper elevation={0} sx={{ p: (theme) => theme.spacing(1, 2, 2) }}>
      <Grid container>
        <Grid container item alignItems="center" xs={12} mb={2}>
          <Typography variant="body1" sx={{ flexGrow: 1 }}>
            <strong>{monthTh}</strong>
            <Typography component="span" color="tertiary" sx={{ ml: 1 }}>
              ({dateTh})
            </Typography>
          </Typography>
          <IconButton onClick={onClickAddHandler} sx={{ ml: "auto", p: 0 }}>
            <AddIcon sx={{ fontSize: "2.5rem" }} />
          </IconButton>
        </Grid>
        <Grid container item xs={12} mb={3}>
          <Grid item xs>
            <Typography textAlign="center" variant="subtitle2" color="tertiary">
              วันที่
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography textAlign="center" variant="subtitle2" color="tertiary">
              รายละเอียด
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography textAlign="center" variant="subtitle2" color="tertiary">
              สถานะ
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            textAlign="center"
            sx={{ color: (theme) => theme.palette.error.light }}
          >
            คุณยังไม่มีงานในระบบ
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

WorkbookCard.propTypes = {
  date: PropTypes.instanceOf(Date),
};

WorkbookCard.defaultProps = {
  date: new Date(),
};

export default WorkbookCard;
