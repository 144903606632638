import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography, Button } from "@mui/material";

import { serviceActions } from "store/services/service-slice";
import { useHttp } from "hooks";
import { ActionLoading } from "base-components";
import {
  ServiceTypeCard,
  AddItemCard,
  VehicleCard,
  WorkbookCard,
} from "components";

import { ROUTES } from "routes/router";
import { DRIVER_ENDPOINTS } from "constants/api";
import { useNavigate } from "react-router-dom";

const DriverMenuPage = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { vehicles: driverVehicles } = useSelector((state) => state.driver);
  const services = useSelector((state) => state.services);

  const navigate = useNavigate();

  const { sendRequest: fetchServicesTypes } = useHttp();
  const { isLoading: isReseting, sendRequest: sendResetRegisterRequest } =
    useHttp();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const fetchHttp = (signal) => {
      fetchServicesTypes(
        { endpoint: DRIVER_ENDPOINTS.serviceTypes, signal },
        (response) => {
          dispatch(serviceActions.updateServiceTypes(response));
        }
      );
    };

    if (!services?.types.length) fetchHttp(signal);

    return () => abortCont.abort();
  }, [dispatch, services, fetchServicesTypes]);

  /**
   * Middleware function for checking role must be driver
   * @param {Function} next callback function to trigger after check role
   * @param {String} argsNextFunc data for using in next function
   * @returns {Function} if driver will first call next callback function
   *                     if not driver will navigate to driver register page
   */
  const checkIsDriver =
    (next) =>
    (argsNextFunc = null) => {
      const {
        role: { farmer, driver },
      } = auth;
      if (farmer && driver) return next(argsNextFunc);
      return navigate(ROUTES.REGISTER_CHANNEL);
    };

  const onClickAddWork = () => {
    console.log("add more work");
  };

  const onClickRegisterService = (serviceName) => {
    console.log("register service: ", serviceName);
  };

  const onClickRegisterMachine = () => {
    console.log("register machine");
  };

  const serviceTypeElements = services?.types?.length
    ? services.types.map((service) => (
        <ServiceTypeCard
          key={service.id}
          imgUrl={service.image}
          name={service.nameTH}
          overlay={service.isRegister ? false : true}
          clickOverlayButton={checkIsDriver(onClickRegisterService)}
        />
      ))
    : null;

  const vehicleCardElements = driverVehicles?.length
    ? driverVehicles.map(({ brand, nameTH }, idx) => (
        <VehicleCard key={idx} brand={brand} vehicleType={nameTH} />
      ))
    : null;

  /* Temporary button */
  const showResetButton =
    auth.tel === "0999999999" ? (
      <Grid container item justifyContent="center" xs={12} mt={1} mb={2}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            sendResetRegisterRequest(
              {
                endpoint: DRIVER_ENDPOINTS.resetRegisterGetztrac,
                method: "PUT",
                body: { tel: auth.tel },
              },
              (response) => {
                navigate(ROUTES.REGISTER_CHANNEL, {
                  replace: true,
                });
              }
            );
          }}
        >
          Reset
        </Button>
      </Grid>
    ) : null;

  return (
    <Fragment>
      <Grid container>
        {showResetButton}
        <Grid container item xs={12} mb={2}>
          <Typography component="h1" variant="h6" sx={{ flexGrow: 1 }}>
            <strong>สมุดงาน</strong>
          </Typography>
          <Typography variant="subtitle1" sx={{ my: "auto", ml: "auto" }}>
            เพิ่มเติม
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <WorkbookCard clickAdd={checkIsDriver(onClickAddWork)} />
        </Grid>

        <Grid item xs={12} mb={2}>
          <Typography component="h1" variant="h6">
            <strong>งานที่ฉันรับได้</strong>
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          sx={{ flexGrow: 1 }}
        >
          {serviceTypeElements}
        </Grid>

        <Grid container item xs={12} mb={2}>
          <Typography component="h1" variant="h6">
            <strong>เครื่องจักรของท่าน</strong>
          </Typography>
          <Typography variant="subtitle1" sx={{ my: "auto", ml: "auto" }}>
            รายละเอียด
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          {vehicleCardElements}
          <AddItemCard
            text="เพิ่มเครื่องจักร"
            clickCard={checkIsDriver(onClickRegisterMachine)}
          />
        </Grid>
      </Grid>

      <ActionLoading open={isReseting} />
    </Fragment>
  );
};

export default DriverMenuPage;
